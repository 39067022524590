var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function ($event) {
        _vm.searchText = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "toolbar-right",
        fn: function () {
          return [
            _vm.isThereSelectedBanks
              ? _c(
                  "pb-btn",
                  {
                    staticClass: "ml-2 my-1",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("addLessonLabel"),
                      text: "",
                      outlined: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.addLesson.apply(null, arguments)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("fal fa-plus")])],
                  1
                )
              : _vm._e(),
            _vm.isThereSelectedBanks
              ? _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on: onMenu }) {
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("actionsLabel"),
                                      text: "",
                                      outlined: "",
                                    },
                                  },
                                  { ...onMenu }
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("fal fa-ellipsis-stroke-vertical"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      648777904
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.importCSV()
                              },
                            },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  color: "primary",
                                  elevation: "0",
                                  text: "",
                                },
                              },
                              [
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("importCSVLabel"))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "pb-btn",
              {
                staticClass: "ml-2 my-1 d-inline-block",
                attrs: {
                  id: "print",
                  color: "primary",
                  label: _vm.$t("sendToLabel"),
                  text: "",
                  outlined: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.showLessonBanksPrintOptions()
                  },
                },
              },
              [_c("v-icon", [_vm._v("fal fa-arrow-up-from-square")])],
              1
            ),
            _c("print-options", { ref: "printOptions" }),
          ]
        },
        proxy: true,
      },
      {
        key: "toolbar-left",
        fn: function () {
          return [
            _c(
              "v-col",
              { staticClass: "px-0", attrs: { sm: "3" } },
              [
                _c("v-text-field", {
                  attrs: {
                    dense: "",
                    outlined: "",
                    clearable: "",
                    "single-line": "",
                    "hide-details": "",
                    "prepend-inner-icon": "fal fa-search",
                    label: _vm.$t("searchLabel"),
                  },
                  model: {
                    value: _vm.searchText,
                    callback: function ($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText",
                  },
                }),
              ],
              1
            ),
            _vm.userType === "D" &&
            _vm.$vuetify.breakpoint.lgAndUp &&
            !_vm.localIsMenuOverlapping
              ? _c(
                  "v-menu",
                  {
                    attrs: {
                      transition: "scroll-y-transition",
                      "offset-y": "",
                      "max-height": "500",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      ref: "districtBankButton",
                                      staticClass: "ml-2",
                                      attrs: {
                                        elevation: "0",
                                        color: "primary",
                                        height: "40",
                                        outlined: "",
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "right",
                                              39,
                                              $event.key,
                                              ["Right", "ArrowRight"]
                                            )
                                          )
                                            return null
                                          if (
                                            "button" in $event &&
                                            $event.button !== 2
                                          )
                                            return null
                                          return _vm.focusDistrictEditButton()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.localDistrictLessonBankName)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1449314361
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          {
                            ref: "districtBankList",
                            attrs: {
                              color: "primary",
                              "aria-label": _vm.$t("districtLessonBanksLabel"),
                            },
                          },
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editOrAddBank(null, null, "DLB")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-2", attrs: { small: "" } },
                                  [_vm._v("fal fa-circle-plus")]
                                ),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "drop-down-list" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("addDistrictLessonBankLabel")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.districtLessonBankItems,
                              function (districtLessonBank, index) {
                                return _c("v-hover", {
                                  key: districtLessonBank.value,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ hover }) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              {
                                                class: {
                                                  "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                    _vm.selectedLessonBankClassId ==
                                                    districtLessonBank.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeLessonBank(
                                                      districtLessonBank.value,
                                                      true,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "drop-down-list",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          districtLessonBank.text
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    ref: "dEditButton" + index,
                                                    refInFor: true,
                                                    staticClass:
                                                      "ml-2 default-button",
                                                    attrs: {
                                                      color: hover
                                                        ? "gray"
                                                        : "transparent",
                                                      icon: "",
                                                      small: "",
                                                      "aria-label":
                                                        _vm.$t("editLabel"),
                                                    },
                                                    on: {
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "right",
                                                              39,
                                                              $event.key,
                                                              [
                                                                "Right",
                                                                "ArrowRight",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          if (
                                                            "button" in
                                                              $event &&
                                                            $event.button !== 2
                                                          )
                                                            return null
                                                          return _vm.focusDistrictDeleteButton.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "left",
                                                              37,
                                                              $event.key,
                                                              [
                                                                "Left",
                                                                "ArrowLeft",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          if (
                                                            "button" in
                                                              $event &&
                                                            $event.button !== 0
                                                          )
                                                            return null
                                                          return _vm.focusDistrictBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "up",
                                                              38,
                                                              $event.key,
                                                              ["Up", "ArrowUp"]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusDistrictBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "down",
                                                              40,
                                                              $event.key,
                                                              [
                                                                "Down",
                                                                "ArrowDown",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusDistrictBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      ],
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.stopPropagation()
                                                        return _vm.editOrAddBank(
                                                          districtLessonBank.value,
                                                          districtLessonBank.text,
                                                          "UDLB",
                                                          districtLessonBank,
                                                          "D"
                                                        )
                                                      },
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.editOrAddBank(
                                                          districtLessonBank.value,
                                                          districtLessonBank.text,
                                                          "UDLB",
                                                          districtLessonBank,
                                                          "D"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("fal fa-pencil")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    ref:
                                                      "dDeleteButton" + index,
                                                    refInFor: true,
                                                    staticClass:
                                                      "ml-2 error-button",
                                                    attrs: {
                                                      color: hover
                                                        ? "error"
                                                        : "transparent",
                                                      icon: "",
                                                      small: "",
                                                      "aria-label":
                                                        _vm.$t("deleteLabel"),
                                                    },
                                                    on: {
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "left",
                                                              37,
                                                              $event.key,
                                                              [
                                                                "Left",
                                                                "ArrowLeft",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          if (
                                                            "button" in
                                                              $event &&
                                                            $event.button !== 0
                                                          )
                                                            return null
                                                          return _vm.focusDistrictEditButton.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "up",
                                                              38,
                                                              $event.key,
                                                              ["Up", "ArrowUp"]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusDistrictBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "down",
                                                              40,
                                                              $event.key,
                                                              [
                                                                "Down",
                                                                "ArrowDown",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusDistrictBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.stopPropagation()
                                                          return _vm.doDeleteLessonBank(
                                                            districtLessonBank.value,
                                                            districtLessonBank.text,
                                                            "D"
                                                          )
                                                        },
                                                      ],
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.doDeleteLessonBank(
                                                          districtLessonBank.value,
                                                          districtLessonBank.text,
                                                          "D"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "fal fa-trash-alt"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$vuetify.breakpoint.lgAndUp && !_vm.localIsMenuOverlapping
              ? _c(
                  "v-menu",
                  {
                    attrs: {
                      transition: "scroll-y-transition",
                      "offset-y": "",
                      "max-height": "500",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      ref: "lessonBankButton",
                                      staticClass: "ml-2",
                                      attrs: {
                                        elevation: "0",
                                        color: "primary",
                                        outlined: "",
                                        height: "40",
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "right",
                                              39,
                                              $event.key,
                                              ["Right", "ArrowRight"]
                                            )
                                          )
                                            return null
                                          if (
                                            "button" in $event &&
                                            $event.button !== 2
                                          )
                                            return null
                                          return _vm.focusBankEditButton()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.localLessonBankName)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1920041105
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          {
                            ref: "lessonBankList",
                            attrs: {
                              color: "primary",
                              "aria-label": _vm.$t("lessonBanksLabel"),
                            },
                          },
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editOrAddBank(null, null, "LB")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-2", attrs: { small: "" } },
                                  [_vm._v("fal fa-circle-plus")]
                                ),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "drop-down-list" },
                                  [_vm._v(_vm._s(_vm.$t("addLessonBankLabel")))]
                                ),
                              ],
                              1
                            ),
                            _vm.isAllowedToCreateSchoolLessonBanks
                              ? _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editOrAddBank(
                                          null,
                                          null,
                                          "SLB"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: { small: "" },
                                      },
                                      [_vm._v("fal fa-circle-plus")]
                                    ),
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "drop-down-list" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("addSchoolLessonBankLabel")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.lessonBankItems,
                              function (lessonBank, index) {
                                return _c("v-hover", {
                                  key: lessonBank.value,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ hover }) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              {
                                                class: {
                                                  "primary--text v-list-item--active v-list-item--link theme--light v-list-item--highlighted":
                                                    _vm.selectedLessonBankClassId ==
                                                    lessonBank.value,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeLessonBank(
                                                      lessonBank.value,
                                                      true,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "drop-down-list",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.truncate(
                                                            lessonBank.text,
                                                            { length: 30 }
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    ref: "editButton" + index,
                                                    refInFor: true,
                                                    staticClass:
                                                      "ml-2 default-button",
                                                    attrs: {
                                                      color: hover
                                                        ? "default"
                                                        : "transparent",
                                                      icon: "",
                                                      small: "",
                                                      "aria-label":
                                                        _vm.$t("editLabel"),
                                                    },
                                                    on: {
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "right",
                                                              39,
                                                              $event.key,
                                                              [
                                                                "Right",
                                                                "ArrowRight",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          if (
                                                            "button" in
                                                              $event &&
                                                            $event.button !== 2
                                                          )
                                                            return null
                                                          return _vm.focusBankDeleteButton.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "left",
                                                              37,
                                                              $event.key,
                                                              [
                                                                "Left",
                                                                "ArrowLeft",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          if (
                                                            "button" in
                                                              $event &&
                                                            $event.button !== 0
                                                          )
                                                            return null
                                                          return _vm.focusLessonBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "up",
                                                              38,
                                                              $event.key,
                                                              ["Up", "ArrowUp"]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusLessonBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "down",
                                                              40,
                                                              $event.key,
                                                              [
                                                                "Down",
                                                                "ArrowDown",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusLessonBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      ],
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        $event.stopPropagation()
                                                        return _vm.editOrAddBank(
                                                          lessonBank.value,
                                                          lessonBank.text,
                                                          "ULB",
                                                          lessonBank,
                                                          "S"
                                                        )
                                                      },
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.editOrAddBank(
                                                          lessonBank.value,
                                                          lessonBank.text,
                                                          "ULB",
                                                          lessonBank,
                                                          "S"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("fal fa-pencil")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    ref: "deleteButton" + index,
                                                    refInFor: true,
                                                    staticClass:
                                                      "ml-2 error-button",
                                                    attrs: {
                                                      color: hover
                                                        ? "error"
                                                        : "transparent",
                                                      icon: "",
                                                      small: "",
                                                      "aria-label":
                                                        _vm.$t("deleteLabel"),
                                                    },
                                                    on: {
                                                      keyup: [
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "left",
                                                              37,
                                                              $event.key,
                                                              [
                                                                "Left",
                                                                "ArrowLeft",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          if (
                                                            "button" in
                                                              $event &&
                                                            $event.button !== 0
                                                          )
                                                            return null
                                                          return _vm.focusBankEditButton.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "up",
                                                              38,
                                                              $event.key,
                                                              ["Up", "ArrowUp"]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusLessonBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "down",
                                                              40,
                                                              $event.key,
                                                              [
                                                                "Down",
                                                                "ArrowDown",
                                                              ]
                                                            )
                                                          )
                                                            return null
                                                          return _vm.focusLessonBankList.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                        function ($event) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          $event.stopPropagation()
                                                          return _vm.doDeleteLessonBank(
                                                            lessonBank.value,
                                                            lessonBank.text,
                                                            "S"
                                                          )
                                                        },
                                                      ],
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.doDeleteLessonBank(
                                                          lessonBank.value,
                                                          lessonBank.text,
                                                          "S"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "fal fa-trash-alt"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }
                            ),
                            _vm.userType !== "D" && _vm.userMode !== "A"
                              ? _c(
                                  "div",
                                  [
                                    _c("v-divider", { staticClass: "mt-5" }),
                                    _c(
                                      "v-subheader",
                                      { staticClass: "justify-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("sharedLessonBanksLabel")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider", { staticClass: "mb-5" }),
                                    _vm._l(
                                      _vm.getSharedClass,
                                      function (sharedLessonBank, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            on: {
                                              click: function ($event) {
                                                return _vm.changeLessonBank(
                                                  sharedLessonBank.classId,
                                                  true,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "drop-down-list" },
                                              [
                                                sharedLessonBank.districtId !==
                                                  0 &&
                                                sharedLessonBank.districtId
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tDistrictBank",
                                                      },
                                                      [_vm._v("D")]
                                                    )
                                                  : sharedLessonBank.schoolId !==
                                                      0 &&
                                                    sharedLessonBank.schoolId
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tSchoolBank",
                                                      },
                                                      [_vm._v("S")]
                                                    )
                                                  : _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tTeacherBank",
                                                      },
                                                      [_vm._v("T")]
                                                    ),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        sharedLessonBank.className
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isThereSelectedBanks &&
            _vm.$vuetify.breakpoint.lgAndUp &&
            !_vm.localIsMenuOverlapping
              ? _c(
                  "v-menu",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isMenuOverlapping(),
                        expression: "!isMenuOverlapping()",
                      },
                    ],
                    attrs: {
                      transition: "scroll-y-transition",
                      "offset-y": "",
                      "max-height": "500",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      ref: "unitButton",
                                      staticClass: "ml-2",
                                      attrs: {
                                        elevation: "0",
                                        color: "primary",
                                        outlined: "",
                                        height: "40",
                                        id: "unitsFilterMenu",
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "right",
                                              39,
                                              $event.key,
                                              ["Right", "ArrowRight"]
                                            )
                                          )
                                            return null
                                          if (
                                            "button" in $event &&
                                            $event.button !== 2
                                          )
                                            return null
                                          return _vm.focusUnitEditButton()
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.selectedUnits)),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3305336188
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      { ref: "unitList" },
                      [
                        !_vm.isCurrentClassShared ||
                        (_vm.$currentUser.isDistrictAdmin &&
                          _vm.isDistrictBank) ||
                        _vm.$currentUser.isAdmin
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addOrEditUnits({})
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-2", attrs: { small: "" } },
                                  [_vm._v("fal fa-circle-plus")]
                                ),
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "drop-down-list" },
                                  [_vm._v(_vm._s(_vm.$t("addUnitLabel")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showAllUnits()
                              },
                            },
                          },
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "drop-down-list" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("allUnitsLabel")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.sharedClassUnits, function (unit) {
                          return _c("v-hover", {
                            key: unit.unitId,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.filterUnits(unit)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(unit.unitNum) +
                                                      " - " +
                                                      _vm._s(unit.unitTitle)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              ref: "uEditButton" + _vm.index,
                                              refInFor: true,
                                              staticClass:
                                                "ml-2 default-button",
                                              attrs: {
                                                color: hover
                                                  ? "default"
                                                  : "transparent",
                                                icon: "",
                                                small: "",
                                                "aria-label":
                                                  _vm.$t("editLabel"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addOrEditUnits(
                                                    unit
                                                  )
                                                },
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.stopPropagation()
                                                  return _vm.addOrEditUnits(
                                                    unit
                                                  )
                                                },
                                                keyup: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    )
                                                      return null
                                                    return _vm.focusUnitDeleteButton.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "left",
                                                        37,
                                                        $event.key,
                                                        ["Left", "ArrowLeft"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 0
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-pencil")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              ref: "uDeleteButton" + _vm.index,
                                              refInFor: true,
                                              staticClass: "ml-2 error-button",
                                              attrs: {
                                                color: hover
                                                  ? "error"
                                                  : "transparent",
                                                icon: "",
                                                small: "",
                                                "aria-label":
                                                  _vm.$t("deleteLabel"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteUnit(
                                                    unit,
                                                    true
                                                  )
                                                },
                                                keyup: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    $event.stopPropagation()
                                                    return _vm.deleteUnit(
                                                      unit,
                                                      true
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "left",
                                                        37,
                                                        $event.key,
                                                        ["Left", "ArrowLeft"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 0
                                                    )
                                                      return null
                                                    return _vm.focusUnitEditButton.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-trash-alt")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        _vm._l(_vm.localClassUnits, function (unit, index) {
                          return _c("v-hover", {
                            key: index,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ hover }) {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.filterUnits(unit)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "drop-down-list" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(unit.unitNum) +
                                                      " - " +
                                                      _vm._s(unit.unitTitle)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              ref:
                                                "uEditButton" +
                                                (_vm.sharedClassUnits.length +
                                                  index),
                                              refInFor: true,
                                              staticClass:
                                                "ml-2 default-button",
                                              attrs: {
                                                color: hover
                                                  ? "default"
                                                  : "transparent",
                                                icon: "",
                                                small: "",
                                                "aria-label":
                                                  _vm.$t("editLabel"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.addOrEditUnits(
                                                    unit
                                                  )
                                                },
                                                keydown: function ($event) {
                                                  if (
                                                    !$event.type.indexOf(
                                                      "key"
                                                    ) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter",
                                                      13,
                                                      $event.key,
                                                      "Enter"
                                                    )
                                                  )
                                                    return null
                                                  $event.stopPropagation()
                                                  return _vm.addOrEditUnits(
                                                    unit
                                                  )
                                                },
                                                keyup: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "right",
                                                        39,
                                                        $event.key,
                                                        ["Right", "ArrowRight"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 2
                                                    )
                                                      return null
                                                    return _vm.focusUnitDeleteButton.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "left",
                                                        37,
                                                        $event.key,
                                                        ["Left", "ArrowLeft"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 0
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-pencil")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              ref:
                                                "uDeleteButton" +
                                                (_vm.sharedClassUnits.length +
                                                  index),
                                              refInFor: true,
                                              staticClass: "ml-2 error-button",
                                              attrs: {
                                                color: hover
                                                  ? "error"
                                                  : "transparent",
                                                icon: "",
                                                small: "",
                                                "aria-label":
                                                  _vm.$t("deleteLabel"),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.deleteUnit(
                                                    unit,
                                                    false
                                                  )
                                                },
                                                keyup: [
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    )
                                                      return null
                                                    $event.stopPropagation()
                                                    return _vm.deleteUnit(
                                                      unit,
                                                      false
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "left",
                                                        37,
                                                        $event.key,
                                                        ["Left", "ArrowLeft"]
                                                      )
                                                    )
                                                      return null
                                                    if (
                                                      "button" in $event &&
                                                      $event.button !== 0
                                                    )
                                                      return null
                                                    return _vm.focusUnitEditButton.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "up",
                                                        38,
                                                        $event.key,
                                                        ["Up", "ArrowUp"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "down",
                                                        40,
                                                        $event.key,
                                                        ["Down", "ArrowDown"]
                                                      )
                                                    )
                                                      return null
                                                    return _vm.focusUnitList.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("fal fa-trash-alt")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }