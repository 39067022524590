
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import MyListMixin from '@/mixins/mylist-mixin';
import { Component } from 'vue-property-decorator';
import PrintOptions from '../prints/PrintOptions.vue';
import { truncate } from 'lodash';

@Component({
  mixins: [MyListMixin],
  components: {
    BaseToolbar,
    PrintOptions
  }
})
export default class LessonBanksToolbar extends BaseToolbar {
  $refs!: {
    printOptions: any,
    lessonBankList: any,
    lessonBankButton: any,
    districtBankList: any,
    districtBankButton: any,
    unitList: any,
    unitButton: any
  }

  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get localDistrictLessonBankName() {
    return this.localComponent.localDistrictLessonBankName || '';
  }

  get editOrAddBank() {
    return this.localComponent.editOrAddBank || this.emptyFunction;
  }

  get districtLessonBankItems() {
    return this.localComponent.districtLessonBankItems || [];
  }

  get selectedLessonBankClassId() {
    return this.localComponent.selectedLessonBankClassId;
  }

  get changeLessonBank() {
    return this.localComponent.changeLessonBank || this.emptyFunction;
  }

  get doDeleteLessonBank() {
    return this.localComponent.doDeleteLessonBank || this.emptyFunction;
  }

  get localLessonBankName() {
    return this.localComponent.localLessonBankName || '';
  }

  get isAllowedToCreateSchoolLessonBanks() {
    return this.localComponent.isAllowedToCreateSchoolLessonBanks;
  }

  get lessonBankItems() {
    return this.localComponent.lessonBankItems || [];
  }

  get truncate() {
    return truncate;
  }

  get getSharedClass() {
    return this.localComponent.getSharedClass || [];
  }

  get isThereSelectedBanks() {
    return this.localComponent.isThereSelectedBanks;
  }

  get selectedUnits() {
    return this.localComponent.selectedUnits || '';
  }

  get addOrEditUnits() {
    return this.localComponent.addOrEditUnits || this.emptyFunction;
  }

  get isCurrentClassShared() {
    return this.localComponent.isCurrentClassShared;
  }

  get showAllUnits() {
    return this.localComponent.showAllUnits || this.emptyFunction;
  }

  get sharedClassUnits() {
    return this.localComponent.sharedClassUnits || [];
  }

  get filterUnits() {
    return this.localComponent.filterUnits || this.emptyFunction;
  }

  get deleteUnit() {
    return this.localComponent.deleteUnit || this.emptyFunction;
  }

  get localClassUnits() {
    return this.localComponent.localClassUnits || [];
  }

  get addLesson() {
    return this.localComponent.addLesson || this.emptyFunction;
  }

  get importCSV() {
    return this.localComponent.importCSV || this.emptyFunction;
  }

  get searchText() {
    return this.localComponent.search || '';
  }

  set searchText(value: string) {
    this.localComponent.search = value;
  }

  get isMenuOverlapping() {
    return this.localComponent.isMenuOverlapping || this.emptyFunction;
  }

  get localIsMenuOverlapping() {
    return this.localComponent.localIsMenuOverlapping || false;
  }

  get isDistrictBank() {
    return this.localComponent.isDistrictBank || false;
  }

  showLessonBanksPrintOptions() {
    this.$store.commit('prints/setLessonBankId', this.selectedLessonBankClassId);
    this.showPrintOptions(this.$refs.printOptions, 'LessonBank')
  }

  focusBankEditButton() {
    try {
      const i = this.$refs.lessonBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      const index = i - ((this.isAllowedToCreateSchoolLessonBanks) ? 2 : 1);
      if (index >= 0) {
        (this.$refs as any)['editButton' + (index)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusBankDeleteButton() {
    try {
      const i = this.$refs.lessonBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      const index = i - ((this.isAllowedToCreateSchoolLessonBanks) ? 2 : 1);
      if (index >= 0) {
        (this.$refs as any)['deleteButton' + (index)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusLessonBankList() {
    try {
      this.$refs.lessonBankButton.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDistrictEditButton() {
    try {
      const index = this.$refs.districtBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      if (index >= 0) {
        (this.$refs as any)['dEditButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDistrictDeleteButton() {
    try {
      const index = this.$refs.districtBankList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      })
      if (index >= 0) {
        (this.$refs as any)['dDeleteButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusDistrictBankList() {
    try {
      this.$refs.districtBankButton.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusUnitEditButton() {
    try {
      const index = this.$refs.unitList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      }) - 1;
      if (index >= 0) {
        (this.$refs as any)['uEditButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusUnitDeleteButton() {
    try {
      const index = this.$refs.unitList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      }) - 1;
      if (index >= 0) {
        (this.$refs as any)['uDeleteButton' + (index - 1)][0].$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusUnitList() {
    try {
      this.$refs.unitButton.$el.focus();
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }
}
